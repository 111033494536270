/* src/components/tests/VSeekerTest.css */
.v-seeker-test {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.v-seeker-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 5px 0;
}

.v-seeker-button {
  width: 40px;
  height: 40px;
  margin: 2px;
  font-size: 24px;
  text-align: center;
  cursor: pointer;
}

@media (max-width: 600px) {
  .v-seeker-row {
    flex-basis: 50%;
    justify-content: space-evenly;
  }

  .v-seeker-button {
    height: 2.1em;
    font-size: 20px;
    min-width: 18%;
  }
}

.v-seeker-row.fading {
  animation: fadeOut 0.5s forwards;
}

.v-seeker-row:not(.fading) {
  animation: fadeIn 0.5s forwards;
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
