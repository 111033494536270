/* src/App.css */
.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header h1 {
  font-size: 1em;
}

nav {
  width: 100%;
  background: #f0f0f0;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  margin-bottom: 2em;
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

nav ul li {
  margin: 0 15px;
}

nav ul li button {
  text-decoration: none;
  color: darkslategray;
  border: none;
  font-weight: 700;
  font-family: sans-serif;
  font-size: 1em;
}

nav ul li button:hover {
  text-decoration: underline;
  cursor: pointer;
}

div >p {
  max-width: 800px;
  margin: auto;
  margin-top: 1em;
  margin-bottom: 1em;
}

.leftbody {
  text-align: left;
  margin: auto;
  max-width: 800px;
}


/* FORM styles */
form {
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  width: auto;
  max-width: 300px;
}

form label {
  display: block;
  margin: 10px 0;
}

form input[type="text"],
form input[type="number"],
form input[type="email"],
form select {
  display: block;
  width: 100%;
  padding: 8px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-width: 280px;
}

.ContactForm textarea {
  width: 100%;
  height: 8em;
  border: 1px solid #ccc;
  border-radius: 4px;
}

form input[type="checkbox"] {
  margin-right: 10px;
}

form button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  background-color: #61dafb;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

form button:hover {
  background-color: #21a1f1;
}

.results {
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  width: 300px;
}

.results p {
  margin: 10px 0;
}

.results button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  background-color: #61dafb;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.results button:hover {
  background-color: #21a1f1;
}


